import React from 'react';
import { navigate } from 'gatsby';

import 'assets/styles/app.scss';

import Card from '@moonshineragency/ui/src/components/Card/Card';
import Button from '@moonshineragency/ui/src/components/Button/Button';
import Link from '@moonshineragency/ui/src/components/Link/Link';

import Form, {
  Field,
  SubmitButton,
} from '@moonshineragency/ui/src/components/Forms/Forms';

import Container from 'components/Container/Container';
import Layout from 'components/Layout/Layout';

const IndexPage = () => {
  return (
    <Layout title="Stromanschluss Online">
      <Container className="py-3">
        <Card
          className="mb-3"
          title="Blitzschnell zu Ihren neuen Stromanschluss"
        >
          <p>
            Unser Online-Assistent unterstützt Sie in wenigen Schritten und ganz
            bequem bei der Herstellung Ihres neuen Stromanschlusses.
          </p>
          <Button
            theme="primary"
            onClick={() => navigate('/consumer/example1')}
          >
            Jetzt Stromanschluss anfragen
          </Button>
        </Card>
        <Card className="mb-3" title="Sie haben bereits einen Stromanschluss?">
          <p>
            Geben Sie uns eine{' '}
            <Link className="font-weight-bold" to="/#">
              Änderung
            </Link>{' '}
            Ihrer Bezugsanlage oder eine neue{' '}
            <Link className="font-weight-bold" to="/#">
              Netzeinspeiseanlage
            </Link>{' '}
            bekannt (z.B. Photovoltaik oder Batterie). Sie können Ihre Anlage
            auch von uns{' '}
            <Link className="font-weight-bold" to="/#">
              wiedereinschalten
            </Link>{' '}
            oder{' '}
            <Link className="font-weight-bold" to="/#">
              abschalten
            </Link>{' '}
            lassen.
          </p>
        </Card>
        <Card title="Bearbeitungsstatus anzeigen">
          <Form
            onValidate={values => {
              const errors = {};
              if (!values.nachname) {
                errors.nachname = 'Bitte geben sie Ihren Nachnamen an';
              }
              if (values.bearbeitungsnummer.length !== 8) {
                errors.bearbeitungsnummer =
                  'Die Bearbeitungsnummer besteht aus 8 Buchstaben';
              }
              if (Object.keys(errors).length) {
                throw errors;
              }
            }}
            values={{
              nachname: null,
              bearbeitungsnummer: null,
            }}
            onSubmit={() => {
              navigate('/comsumer/step1');
            }}
          >
            <div className="mb-3">
              <Field
                name="nachname"
                type="text"
                label="Nachname"
                className="mb-3"
              />
            </div>
            <div className="mb-3">
              <Field
                className="mb-3"
                name="bearbeitungsnummer"
                type="text"
                label="Bearbeitungsnummer"
                hint="Sie finden Ihre Bearbeitungsnummer in der Anfragebestätigung, die wir Ihnen per E-Mail gesendet haben."
              />
            </div>

            <SubmitButton theme="secondary">
              Bearbeitungsstatus anzeigen
            </SubmitButton>
          </Form>
        </Card>
      </Container>
    </Layout>
  );
};

export default IndexPage;
